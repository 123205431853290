import { FaInstagram, FaTiktok, FaFacebook } from "react-icons/fa";
function Footer() {
  return (
    <div className="divfooter">
       <div className="social-icons">
        <a href="https://www.facebook.com/profile.php?id=100090176464932">
          <FaFacebook />
        </a>
        <a href="https://www.tiktok.com/@salary_confessions_il">
          <FaTiktok />
        </a>
        <a href="https://www.instagram.com/salary_confessions_il/">
          <FaInstagram />
        </a>
      </div>
      <span className="tnwuijualdzvc">
        ריפרש היא פלטפורמה לשיתוף וידויי שכר אנונימיים משוק העבודה הישראלי.
        בעזרת ריפרש תוכלו לגלות את השכר האמיתי בתפקיד שלכם ושל הקולגות !
      </span> 
  
       </div>
  )
}

export default Footer