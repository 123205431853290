import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import ModalProvider from "../components/ModalProvider";
import { getSubmissionCount, incrementSubmissionCount } from "../utils/localStorage";

function AiCareer() {
  const [apiResponse, setApiResponse] = useState({});
  

  const [jobPreference, setJobPreference] = useState("");
  const [jobInterest, setJobInterest] = useState("");
  const [extraDetails, setExtraDetails] = useState("");
  const [loading, setLoading] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false)

  // const generateText = async (e) => {
  //   e.preventDefault();

  //   if (jobPreference === "" || jobInterest === "" || extraDetails === "") {
  //     alert("אנא מלא את כל השדות");
  //     return;
  //   }
  //   const message = [
  //     {
  //       role: "user",
  //       content: `Based on the following profile, what career options would be a good fit? 
  //           Job Preference: ${jobPreference}
  //           Job Interest: ${jobInterest}
  //           Extra Details: ${extraDetails}
  //           respond with 3 career options formatted like this:
  //           1. career option 1
  //           2. career option 2
  //           3. career option 3
  //           return the answer in hebrew
  //           `,
  //     },
  //   ];
  //   setLoading(true);
  //   const result = await axios.post(
  //     "https://api.openai.com/v1/chat/completions",
  //     {
  //       model: "gpt-3.5-turbo",
  //       messages: message,
  //       max_tokens: 500,
  //       temperature: 0,
  //     },
  //     {
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${OPENAI_API_KEY}`,
  //       },
  //     }
  //   );
  //   setLoading(false);
  //   console.log(result.data);
  //   setApiResponse(result.data);
  // };

  const generateText = async (e) => {
    e.preventDefault();

    const submissionCount = getSubmissionCount();
    if (submissionCount >= 3) {
      alert("בשלב זה ניתן להשתמש בממשק זה עד 3 פעמים בלבד. הישארו מעודכנים.");
      return;
    }
  
    if (jobPreference === "" || jobInterest === "" || extraDetails === "") {
      alert("אנא מלא את כל השדות");
      return;
    }
    const message = [
      {
        role: "system",
        content: "You are a career counselor who knows what career options will be a good fit for different profiles. only suggest known jobs which exist in israel",
      },
      {
        role: "user",
        content: `Based on the following profile, what career options would be a good fit? 
          Job Preference: ${jobPreference}
          Job Interest: ${jobInterest}
          Extra Details: ${extraDetails}
          respond with 2 career options formatted like this:
          1. career option 1
          2. career option 2
          only return the names of the careers in hebrew
         
          `,
    },
    ];
    setLoading(true);
    try {
      const result = await axios.post(
        "/.netlify/functions/openai",
        {
          messages: message,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setLoading(false);
      // console.log(result.data);
      incrementSubmissionCount();
      setApiResponse(result.data);
      setFormSubmitted(true);
    } catch (error) {
      setLoading(false);
      // console.error(error);
    }
  };

  return (
    <>
      <ModalProvider>

      

      <main className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8 mt-24 ">
        <p className='searchText'>
          חושבים במה לעבוד? תנו לממשק ה-AI שלנו לעזור לכם להחליט. פשוט מלאו את הפרטים הבאים ותקבלו המלצות על תחומי עבודה שיכולים להתאים לכם.
        </p>
  <p className='text-black-200 text-sm'>מונע ע"י <a href="https://openai.com/" target="_blank" rel="noopener noreferrer" className="text-teal-600 hover:text-teal-500">OpenAI</a></p>
    <br></br>
        <div className="flex flex-col sm:flex-row lg:gap-8">
          <div className="bg-gray-300 py-4 px-1 shadow sm:rounded-lg sm:px-10 flex-1 mb-4 sm:mb-0 ">
            <h3 className="text-xl text-teal-600 mb-2 font-bold">
              כמה פרטים קטנים עליכם
            </h3>
            <form onSubmit={generateText}>
              <div className="form-group">
                <label htmlFor="contact-goals">מה הכי חשוב לך במקצוע?</label>
                <select
                  id="contact-goals"
                  className="form-control form-text"
                  required
                  value={jobPreference}
                  onChange={(e) => setJobPreference(e.target.value)}
                >
                  <option value="">--בחר אפשרות--</option>
                  <option value="High salary">שכר גבוה</option>
                  <option value="Work-life balance">
                    איזון בין עבודה לחיים האישיים
                  </option>
                  <option value="Career advancement">אפשרויות לקידום</option>
                  <option value="Job security">ביטחון משרה</option>
                  <option value="Creative opportunities">
                    אפשרויות ליצירתיות
                  </option>
                  <option value="Learning opportunities">
                    אפשרויות ללמידה
                  </option>
                </select>
              </div>

              <div className="form-group">
                <label htmlFor="contact-interests">תחומי עניין:</label>
                <textarea
                  className="form-control form-text"
                  id="contact-interests"
                  rows="3"
                  required
                  placeholder="אנא ציינו עד שלושה תחומי עניין"
                  maxLength={"40"}
                  value={jobInterest}
                  onChange={(e) => setJobInterest(e.target.value)}
                ></textarea>
              </div>

              <div className="form-group">
                <label htmlFor="contact-other">פרטים נוספים שחשובים לך:</label>
                <textarea
                  className="form-control form-text"
                  id="contact-other"
                  rows="3"
                  maxLength={"50"}
                  value={extraDetails}
                  onChange={(e) => setExtraDetails(e.target.value)}
                  placeholder="אופק קידום, יציבות, וכו'"
                ></textarea>
              </div>

              <button type="submit" className="bg-yellow-400 hover:bg-yellow-500 rounded-xl px-5 text-lg font-bold transition duration-300 ease-in-out">שלח</button>
            </form>
          </div>

          <div className="bg-gray-300 rounded-lg shadow-lg overflow-hidden flex-1 mb-8 sm:mb-0">
            <div className="p-4">
              <h3 className="text-xl text-teal-600 mb-2 font-bold">מה יכול להתאים לי?</h3>
              <p className="text-black-500 font-bold mb-4 text-xl leading-10">
              {loading ? 'כמה רגעים...' : apiResponse.choices && apiResponse.choices[0].message.content.split('\n').map((item, key) => {
    return <span key={key}>{item}<br/></span>})}
                </p>
                {!loading && apiResponse.choices && 
                <p className="text-black-500 font-bold mb-4 text-md">
                  המידע המוצג נוצר על ידי GPT 
                  .ואינו מהווה תחליף לייעוץ מקצועי. אנא קחו בחשבון כי המידע עלול להיות מוטעה ומטעה ונועד לשמש ככלי עזר.
                 <br/>
                  <br/>
                 <button type="submit" className="bg-yellow-400 hover:bg-yellow-500 rounded-xl px-5 text-lg font-bold transition duration-300 ease-in-out">
                  <Link to='/roles'>גלה עוד תפקידים</Link>
                  </button>

               
              </p>
}
            </div>
          </div>
        </div>
      </main>

      </ModalProvider>
    </>
  );
}

export default AiCareer;
