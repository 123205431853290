import { useState } from "react";
import supabase from "../supabase";
import ReCAPTCHA from "react-google-recaptcha";



const CATEGORIES = [
    { name: "הייטק", color: "#00323E" },
    { name: "פיננסים וביטוח", color: "#004E58" },
    { name: "כללי", color: "#00706C" },
    { name: "המגזר הציבורי", color: "#1F9894" },
    { name: "עצמאים", color: "#F68D47" },
    { name: "משפטים", color: "#EF6E3F" },
    { name: "מכירות", color: "#DD4C39" },
    { name: "אחר", color: "#C83641" },
    { name: "עיצוב, פרסום ושיווק", color: "#D45E64" },
  ];
function NewSalaryForm({
    setSalaries,
    setShowForm,
    onFormSubmit,
    handleCloseModal,
  }) {
    const [role, setRole] = useState("");
    const [salary, setSalary] = useState();
    const [company, setCompany] = useState("");
    const [expiernce, setExpiernce] = useState("");
    const [education, setEducation] = useState("");
    const [age, setAge] = useState();
    const [more, setMore] = useState("");
    const [category, setCategory] = useState("");
    const [isUploading, setIsUploading] = useState(false);
    const [error, setError] = useState(false);
    const [isRecaptchaValid, setIsRecaptchaValid] = useState(false);    
    const [errorCapcha, setErrorCapcha] = useState(false);


  
    
    function recaptchaCallback() {
        setIsRecaptchaValid(true);
        

      }


    async function handleSubmit(e) {
      e.preventDefault();

      if (!isRecaptchaValid) {
        setErrorCapcha(true);
        setTimeout(() => setErrorCapcha(false), 3000); // hide the error message after 3 seconds

        return;
      }


  
      // check if data is valid, if so create new fact
      if (role && salary && expiernce && education && category) {
        // upload fact to supabase and revevie the new fact object
        setIsUploading(true);
        const { data: newSalary, error } = await supabase
          .from("salaries")
          .insert([
            { role, salary, company, expiernce, education, age, more, category },
          ])
          .select();
        setIsUploading(false);
        onFormSubmit();
        handleCloseModal();
  
        // add the fact to the UI: add the fact to state but only if it's approved
        if (!error) {
          if (newSalary[0].approved) {
            setSalaries((salaries) => [newSalary[0], ...salaries]);
          }
        } else {
          setError(true);
        }
  
        // reset input fields
        setRole("");
        setSalary();
        setCompany("");
        setExpiernce("");
        setEducation("");
        setAge();
        setCategory("");
  
  
      } else {
        setError(true);
      }


    }
  
    return (
      <>
        <div className="modal">
          <div className="modal-content">
            <button className="close-btn" onClick={handleCloseModal}>
              X
            </button>
  
            <form className="fact-form" onSubmit={handleSubmit}>
              <div className="form-row">
                <input
                  type="text"
                  placeholder="תפקיד"
                  value={role}
                  onChange={(e) => setRole(e.target.value)}
                  disabled={isUploading}
                />
  
                <input
                  type="number"
                  placeholder="שכר"
                  value={salary}
                  onChange={(e) => setSalary(e.target.value.toLocaleString())}
                />
  
                <input
                  type="text"
                  placeholder="חברה"
                  value={company}
                  onChange={(e) => setCompany(e.target.value)}
                />
                <input
                  type="text"
                  placeholder="ניסיון"
                  value={expiernce}
                  onChange={(e) => setExpiernce(e.target.value)}
                />
              </div>
              <div className="form-row">
                <input
                  type="text"
                  placeholder="השכלה"
                  value={education}
                  onChange={(e) => setEducation(e.target.value)}
                />
                <input
                  type="number"
                  placeholder="גיל"
                  value={age}
                  onChange={(e) => setAge(e.target.value)}
                />
  
                <select
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                  disabled={isUploading}
                >
                  <option value="">תחום</option>
                  {CATEGORIES.map((cat) => (
                    <option key={cat.name} value={cat.name}>
                      {cat.name.toUpperCase()}
                    </option>
                  ))}
                </select>
  
                <textarea
                  type="text"
                  placeholder="תנאים והטבות"
                  value={more}
                  onChange={(e) => setMore(e.target.value)}
                />
              </div>
              
              
              <ReCAPTCHA className="captcha"
    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
    onChange={recaptchaCallback}
    
  />      {error && (
                <p className="message">
                  נראה שאחד או יותר מהסעיפים חסרים, אנא נסה שוב
                </p>
              )}

             {errorCapcha && (
                <p className="message">
                  אנא אשר שאינך רובוט
                </p>
              )}
              <button className="bg-yellow-400 rounded-xl px-5 text-lg font-bold" disabled={isUploading}>
                שתף
              </button>
            </form>
          </div>
        </div>
      </>
    );
  }

  export default NewSalaryForm;