import SearchBar from "./SearchBar";
import SalarySubmitted from "./SalarySubmitted";
import Navbar from "./Navbar/Navbar";
import { FaFacebook, FaInstagram, FaTiktok, FaBars } from "react-icons/fa";
import SubmitConfessionModal from "./SubmitConfessionModal";
import { useState } from "react";


import { Link, NavLink, useLocation } from "react-router-dom";
function Header({
    formSubmitted,
    showModal,
    handleOpenModal,
    setSalaries,
    setIsLoading,
    salaries,
    currentCategory,
    searchQuery,
    setSearchQuery,
    setCurrentCategory,
    isSuccess,
  }) 
  {
    const location = useLocation();
    const [showMenu, setShowMenu] = useState(false);

    const toggleMenu = () => {
      setShowMenu(!showMenu);
    };

    return (
      <>
<header className="bg-teal-600 py-4 flex justify-between items-center w-full headerMobile">
      <div className="header-content">
        <div className="flex items-center">
        {/* <button className="menu-btn hidden md:block" onClick={toggleMenu}>
  <FaBars className="text-white text-xl" />
</button> */}

          <a href="/">
            <div className="title-container">
            <h1 className="text-white text-3xl font-bold align-start refreshHeader">ריפרש</h1>
            </div>
            <h2 className="text-white text-xl">זינוק לקריירה שלך</h2>
          </a>
        </div>

        <div className={`menu-btn block md:hidden ${showMenu ? 'active' : ''}`} onClick={toggleMenu}>
  <FaBars className="text-white text-xl" />
</div>


        <nav className={`text-center flex-grow font-semibold ${showMenu ? 'block' : 'hidden'} md:block`}>
      <ul className="flex justify-center">
        <li className="mr-6">
          <Link
            to="/"
            className={`text-white hover:text-gray-300 ${
              location.pathname === "/" ? "text-yellow-400" : ""
            }`}
          >
            וידויי שכר
          </Link>
        </li>
        <li className="mr-6">
          <Link
            to="/confessions"
            className={`text-white hover:text-gray-300 ${
              location.pathname === "/confessions" ? "text-yellow-400" : ""
            }`}
          >
            וידויי קריירה
          </Link>
        </li>
        <li className="mr-6">
          <Link
            to="/roles"
            className={`text-white hover:text-gray-300 ${
              location.pathname === "/roles" ? "text-yellow-400" : ""
            }`}
          >
            תפקידים בהייטק
          </Link>
        </li>
        <li className="mr-6">
          <Link
            to="/ai-career"
            className={`text-white hover:text-gray-300 ${
              location.pathname === "/ai-career" ? "text-yellow-400" : ""
            }`}
          >
            AI Career
          </Link>
        </li>
        <li className="mr-6">
          <Link
            to="/studies"
            className={`text-white hover:text-gray-300 ${
              location.pathname === "/studies" ? "text-yellow-400" : ""
            }`}
          >
            לימודים
          </Link>
        </li>
        <li className="mr-6">
          <Link
            to="/about"
            className={`text-white hover:text-gray-300 ${
              location.pathname === "/about" ? "text-yellow-400" : ""
            }`}
          >
            עלינו
          </Link>
        </li>

        <li className="mr-6">
          
          <button
            className="bg-yellow-400 rounded-xl px-4"
            onClick={handleOpenModal}
          >
            {showModal}
            הוסף וידוי
          </button>{isSuccess && <SalarySubmitted />}
        </li>
      </ul>
    </nav>

  <div className="flex items-center ml-4 socialHeader">
    <a href="https://www.facebook.com/profile.php?id=100090176464932">
      <FaFacebook className="text-white text-xl mr-4" />
    </a>
    <a href="https://www.tiktok.com/@salary_confessions_il">
      <FaTiktok className="text-white text-xl mr-4" />
    </a>
    <a href="https://www.instagram.com/salary_confessions_il/">
      <FaInstagram className="text-white text-xl mr-4" />
    </a>
  </div>
  </div>
</header>
      </>
    );
  }

  export default Header;