import { useEffect, useState } from 'react'
import {useParams, Link} from 'react-router-dom'
import supabaseRoles from '../supabaseRoles'
import Navbar from '../components/Navbar/Navbar'
import Loader from '../components/Loader'
import './Roles.css'
import Header from '../components/Header'
import ModalProvider from '../components/ModalProvider'


function Role() {

    const {roleId} = useParams()
    const [data, setData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    useEffect(() => {
        const getRole = async () => {
          setIsLoading(true)
            let {data, error} = await supabaseRoles.from('role-data').select('*').eq('id', roleId).single()
            if (error) {
                console.log(error)
            }
            else {
                setData(data)
                setIsLoading(false)
            }

        }
        getRole()
    }, [roleId])

  return (
    <>
<ModalProvider>
  <main className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8 mt-24 ">
    <h1 className="text-center text-3xl mb-4 text-teal-600 font-bold">על התפקיד...</h1>
    <div>
      <div className="content-wrapper-role">
        {isLoading && <Loader />}
        {!isLoading && (
          <>
            <li className="roleFact" style={{ margin: "0 auto" }}>
              <p className="role">תפקיד: {data.role}</p>
              <p>שכר ממוצע : {data.avgSalary}</p>
              <p>חברה: {data.roleDescription || "לא צוין"}</p>

              <div className="stack">
                <p>טכנולוגיות: </p>
                {data.RoleStack?.split(",")?.map((tool) => (
                  <span key={tool.trim()}>{tool.trim()}</span>
                )) ?? "בטעינה"}
              </div>
            </li>       <div className="btnDiv">
          <Link to="/roles">
            <button className="backHome">חזרה לתפקידים</button>
          </Link>
        </div>
          </>
        )}
 
      </div>
    </div>
  </main>
</ModalProvider>



      </>  )
}

export default Role