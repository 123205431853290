import { useCallback, useEffect, useState } from "react";
import supabase from "./supabase";
import "./style.css";
// import social media icons from react-icons
import { FaInstagram, FaTiktok, FaFacebook } from "react-icons/fa";
import { BrowserRouter, Route, Routes, Link } from "react-router-dom";
import SalaryList from "./components/SalaryList";
import CategoryFilter from "./components/CategoryFilter";
import NewSalaryForm from "./components/NewSalaryForm";
import Header from "./components/Header";
import Loader from "./components/Loader";
import About from "./pages/About";
import Footer from "./components/Footer";
import ScrollToTopButton from "./components/ScrollToTop";
import SearchBar from "./components/SearchBar";
import PageBtns from "./components/PageBtns";
import SubmitConfessionModal from "./components/SubmitConfessionModal";
import ModalProvider from "./components/ModalProvider";


function App() {
  const [salaries, setSalaries] = useState([]);
  // const [careerConfessions, setCareerConfessions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentCategory, setCurrentCategory] = useState("הכול");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [showModal, setShowModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [shouldFetchSalaries, setShouldFetchSalaries] = useState(false);
  const [shouldResetSearch, setShouldResetSearch] = useState(false);


  function scrollToTop() {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }

  useEffect(() => {
    scrollToTop();
  }, [page]);

  function handleOpenModal() {
    setShowModal(true);
  }

  function handleCloseModal() {
    setShowModal(false);
  }


  const getSalaries = useCallback(async () => {
    setIsLoading(true);

    let query = supabase.from("salaries").select("*");

    if (currentCategory !== "הכול") {
      query = query.eq("category", currentCategory).eq("approved", true);
    }

    // if (currentCategory !== "הכול") {
    //   query = query.eq("category", currentCategory).eq("approved", true);
    // } else {
    //   query = query.eq("approved", true);
    // }

    const { data: salaries, error } = await query
      // .order("total_votes", { ascending: false })
      .order("id", { ascending: false })

      .eq("approved", true)
      .limit(1000);

    if (!error) setSalaries(salaries);
    else <p className="message">אופס!: {error.message}</p>;
    setIsLoading(false);
  }, [currentCategory]);

  useEffect(() => {
    getSalaries();
  }, [currentCategory, getSalaries]);

  useEffect(() => {
    if (shouldFetchSalaries) {
      getSalaries();
      setShouldFetchSalaries(false);
    }
  }, [shouldFetchSalaries, getSalaries]);

  const handleFormSubmit = (e) => {
    setFormSubmitted(true);
    setTimeout(() => {
      setFormSubmitted(false);
    }, 3000);
  };

  const startIndex = (page - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const displayedSalaries = salaries.slice(startIndex, endIndex);

  const handlePreviousPageClick = () => {
    setPage(page - 1);
  };

  const handleNextPageClick = () => {
    setPage(page + 1);
  };


  // make a function that will scroll to the top of the page when the category is changed
  function scrollToTop() {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }

  useEffect(() => {
    scrollToTop();
  }, [currentCategory]);

  const handleCareerFormSubmit = (e) => {
    setFormSubmitted(true);
    setTimeout(() => {
      setFormSubmitted(false);
    }, 3000);
  };

  useEffect(() => {
    if (!shouldResetSearch) {
      getSalaries();
    }
  }, [shouldResetSearch, getSalaries]);

  return (
    <>
    <ModalProvider
    formSubmitted={formSubmitted}
    handleOpenModal={handleOpenModal}
    handleCloseModal={handleCloseModal}
    onFormSubmit={handleCareerFormSubmit}
    >

      {/* <Header
        formSubmitted={formSubmitted}
        handleOpenModal={handleOpenModal}
        setSalaries={setSalaries}
        setIsLoading={setIsLoading}
        salaries={salaries}
        currentCategory={currentCategory}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
      /> */}

  

      {/* {showModal ? (
        <SubmitConfessionModal closeModal={handleCloseModal}
        setSalaries={setSalaries}
        onFormSubmit={handleCareerFormSubmit}

        />
      ) : null} */}



<main className="main" >
  <div>
    <CategoryFilter
      setCurrentCategory={setCurrentCategory}
      salaries={salaries}
      setSalaries={setSalaries}
      getSalaries={getSalaries}
      setSearchQuery={setSearchQuery}
      scrollToTop={scrollToTop}
      setPage={setPage}
      setShouldFetchSalaries={setShouldFetchSalaries}
      shouldResetSearch={shouldResetSearch}
      setShouldResetSearch={setShouldResetSearch}
    />
  </div>




  <div>
    
    <div className="search-bar search-bar-mobile">
    <SearchBar
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              salaries={salaries}
              setSalaries={setSalaries}
              setIsLoading={setIsLoading}
              currentCategory={currentCategory}
              setCurrentCategory={setCurrentCategory}
              shouldResetSearch={shouldResetSearch}
              setShouldResetSearch={setShouldResetSearch}
            />
    </div>
    {isLoading ? (
      <Loader />
    ) : (
      <>
      <SalaryList
        salaries={salaries}
        setSalaries={setSalaries}
        page={page}
        setPage={setPage}
        pageSize={pageSize}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
      />

<div className="flex justify-start mt-4">
      <button
        className="btn-page mr-2"
        disabled={page === 1}
        onClick={() => setPage(page - 1)}
      >
        עמוד קודם
      </button>
      <button
        className="btn-page"
        disabled={endIndex >= salaries.length}
        onClick={() => setPage(page + 1)}
      >
        עמוד הבא
      </button>
    </div>
      </>
    )}
  
  </div>

  <ScrollToTopButton />
</main>
      <Footer />
    </ModalProvider>
    </>
  );
}

const CATEGORIES = [
  { name: "הייטק", color: "#00323E" },
  { name: "פיננסים וביטוח", color: "#004E58" },
  { name: "כללי", color: "#00706C" },
  { name: "המגזר הציבורי", color: "#1F9894" },
  { name: "עצמאים", color: "#F68D47" },
  { name: "משפטים", color: "#EF6E3F" },
  { name: "מכירות", color: "#DD4C39" },
  { name: "אחר", color: "#C83641" },
  { name: "עיצוב, פרסום ושיווק", color: "#D45E64" },
];

export default App;
