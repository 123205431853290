import { useState } from "react";
import React from "react";

import supabase from "../supabase";
import ReCAPTCHA from "react-google-recaptcha";

function CareerConfessionForm({handleCloseModal, onFormSubmit, setCareerConfessions}) {


  
  const [confession, setConfession] = React.useState('')
  const [category, setCategory] = React.useState('')
  const [error, setError] = React.useState(false)
  const [isRecaptchaValid, setIsRecaptchaValid] = useState(false);    
  const [errorCapcha, setErrorCapcha] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const CATEGORIES = [
    { name: "הייטק", color: "#00323E" },
    { name: "פיננסים וביטוח", color: "#004E58" },
    { name: "כללי", color: "#00706C" },
    { name: "המגזר הציבורי", color: "#1F9894" },
    { name: "עצמאים", color: "#F68D47" },
    { name: "משפטים", color: "#EF6E3F" },
    { name: "מכירות", color: "#DD4C39" },
    { name: "אחר", color: "#C83641" },
    { name: "עיצוב, פרסום ושיווק", color: "#D45E64" },
  ];



    // function recaptchaCallback() {
    //   setIsRecaptchaValid(true);
    //   }


  async function handleSubmit(e) {
    e.preventDefault();

    // if (!isRecaptchaValid) {
    //   setErrorCapcha(true);
    //   setTimeout(() => setErrorCapcha(false), 3000); // hide the error message after 3 seconds

    //   return;
    // }

    // check if data is valid, if so create new fact
    if (confession && category) {
      setIsUploading(true)

      // upload to supabase
      const { data, error } = await supabase
        .from("career-confessions")
        .insert([{ confession, category }])
        .select()
        setIsUploading(false)
        onFormSubmit();
        handleCloseModal();

      if (!error) {
        if (data[0].approved) {
          setCareerConfessions((careerConfessions) => [data[0], ...careerConfessions]);
        }
      } else {
        setError(true)
      }

        setConfession('')
        setCategory('')

      } else {
        setError(true)
      }
    }
  return (
    <>
      <div className="modal">
    <div className="modal-content">
    <p className="searchText mt-2 mb-2 mr-14">איזה כיף שבחרת לשתף!</p>

      <form className="fact-form form-career" onSubmit={handleSubmit} >
          <textarea className="confession-input"
            value={confession}
            onChange={(e) => setConfession(e.target.value)}
            type="text"
            placeholder="וידוי"
            disabled={isUploading}
            cols='50'
          />
         <div className="career-category-div">
          <select className="career-category"
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                  disabled={isUploading}
                >
                  <option value="">תחום</option>
                  {CATEGORIES.map((cat) => (
                    <option key={cat.name} value={cat.name}>
                      {cat.name.toUpperCase()}
                    </option>
                  ))}
                </select>
          </div>

          {/* <ReCAPTCHA className="captcha"
    sitekey= {process.env.REACT_APP_RECAPTCHA_SITE_KEY}
    
    onChange={recaptchaCallback}
    
  />  */}
              
             
    {error && (
                <p className="message">
                  נראה שאחד או יותר מהסעיפים חסרים, אנא נסה שוב
                </p>
              )}

{errorCapcha && (
                <p className="message">
                  אנא אשר שאינך רובוט
                </p>
              )}
 <div className="button-container">
    <button className="bg-yellow-400 rounded-xl px-12 text-lg font-bold" disabled={isUploading}>שלח</button>
    <button className="bg-gray-400 rounded-xl px-12 text-lg font-bold" onClick={handleCloseModal}>סגור</button>
    
  </div>
  
             
            </form>
          </div>
        </div>
    </>
  )
}

export default CareerConfessionForm