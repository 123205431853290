import { useEffect, useState } from "react";
import { FacebookShareButton } from "react-share";
import { BiUpvote, BiDownvote, BiShocked } from "react-icons/bi";
import { Link, useParams } from "react-router-dom";
import supabase from "../supabase";
import { Helmet } from 'react-helmet';
import ModalProvider from "../components/ModalProvider";
import Loader from "../components/Loader";

function SalaryPage({ salary, setSalary, setSalaries }) {
  const { id } = useParams();
  const [salaryPost, setSalaryPost] = useState(null);
  const CATEGORIES = [
    { name: "הייטק", color: "#00323E" },
    { name: "פיננסים וביטוח", color: "#004E58" },
    { name: "כללי", color: "#00706C" },
    { name: "המגזר הציבורי", color: "#1F9894" },
    { name: "עצמאים", color: "#F68D47" },
    { name: "משפטים", color: "#EF6E3F" },
    { name: "מכירות", color: "#DD4C39" },
    { name: "אחר", color: "#C83641" },
    { name: "עיצוב, פרסום ושיווק", color: "#D45E64" },
  ];
  const [isUpdating, setIsUpdating] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    async function getSalary() {
      setIsLoading(true);
      const { data, error } = await supabase
        .from("salaries")
        .select("*")
        .eq("id", id)
        .single();
      if (error) {
        console.log(error);
      } else {
        setSalaryPost(data);
        setIsLoading(false);
      }
    }
    getSalary();
  }, [id]);



 
  return (
    <>
      <Helmet>
        <title>ריפרש</title>
        <meta
          name="description"
          content="ריפרש היא פלטפורמה לשיתוף וידויי שכר וקריירה אנונימיים משוק העבודה הישראלי. גלו כמה אתם אמורים להרוויח באמת !"
        />
        <meta
          property="og:description"
          content="ריפרש היא פלטפורמה לשיתוף וידויי שכר וקריירה אנונימיים משוק העבודה הישראלי"
        />
      </Helmet>
      <ModalProvider>
      <main className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8 mt-24 ">
      <div className="content-wrapper-role">
    {isLoading && <Loader />}
    {salaryPost && (

      <li className="fact"   style={{
        '--tag-color': CATEGORIES.find(
          (cat) => cat.name === salaryPost.category
        ).color,
      }}>
        <p className="role">{salaryPost.role} || {salaryPost.category}</p>
        <p>
          שכר:{" "}
          {salaryPost.salary ? salaryPost.salary.toLocaleString() : "לא צוין"}
        </p>
        <p>חברה: {salaryPost.company || "לא צוין"}</p>
        <p>ניסיון: {salaryPost.expiernce}</p>
        <p>השכלה: {salaryPost.education}</p>
        <p>גיל: {salaryPost.age}</p>
        <p>תנאים והטבות: {salaryPost.more || "לא צוין"}</p>



        {/* <span
          className="tag"
          style={{
            backgroundColor: CATEGORIES.find(
              (cat) => cat.name === salaryPost.category
            ).color,
          }}
        >
          {salaryPost.category}
        </span> */}

        
<div className="vote">
          <FacebookShareButton
            className="fbshare"
            url={`http://refresh-salaries.com/${salaryPost.id}`}
            quote={`רוצים לדעת כמה מרוויח ${salaryPost.role}?`}
          >
            <button className="bg-teal-600 text-white rounded-full px-5 py-1 text-sm">שתף</button>
          </FacebookShareButton>
      </div>
      </li>
    )}
</div>
      <Link to="/">
        <button className="backHome">חזרה לדף הבית</button>
      </Link>
</main>
    </ModalProvider>
    </>
  );
}

export default SalaryPage;
