import { useEffect, useState } from "react";
import supabaseRoles from "../supabaseRoles";
import { Link } from "react-router-dom";
import Navbar from "../components/Navbar/Navbar";
import Loader from "../components/Loader";
import Header from "../components/Header";
import Footer from "../components/Footer";
import ModalProvider from "../components/ModalProvider";



function Studies() {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const getData = async () => {
        let query = supabaseRoles.from("education").select("*");
        const { data, error } = await query;
        if (error) {
            console.log(error);
        } else {
            setData(data);
            setIsLoading(false);

        }
    };

    useEffect(() => {
        getData();
        
    }, []);



  return (
    <>
    <ModalProvider>

        <main className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8 mt-24 min-h-screen ">
            <p className="mr-14 searchText">גלו על התארים המובילים ואיפה ניתן ללמוד אותם</p>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 py-4 p-6 m-8">

                {isLoading ? (
                    <Loader />
                ) : (
                    data.map((item) => {
                        return (
                            <div key={item.id} className="card bg-gray-200 rounded-lg shadow-lg">
                                <div className="p-4">
                                    <h3 className="text-lg font-bold mb-2 text-teal-600">{item.degree}</h3>
                                    <p className="text-black-500 font-medium text-ml mb-2 line-clamp-4"> {item.description}</p>
                                    {/* <div className="stack">
                                        <p>מוסדות לימוד</p>
                                    {item.places?.split(",")?.map((tool) => (
                                        <span key={tool.trim()}>{tool.trim()}</span>
                                    )) ?? "בטעינה"}
                                    </div>       */}
                                </div>
                                <div className="p-4 flex justify-start">
                                    <Link className="text-teal-600 font-medium" to={`/studies/${item.id}`}>
                                        למוסדות לימוד ומידע נוסף
                                    </Link>
                                </div>
                            </div>
                        );
                    })
                )}
            </div>
        </main>
        <Footer />
    </ModalProvider>


    </>
  )
}

export default Studies