import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import About from "./pages/About";
import SalaryPage from "./pages/SalaryPage";
import CareerConfession from "./pages/CareerConfessions";
import Roles from "./pages/Roles";
import Role from "./pages/Role";
import { createBrowserRouter, RouterProvider, Route } from "react-router-dom";
import Contact from "./pages/Contact";
import Design from "./pages/Design";
import AiCareer from "./pages/AiCareer";
import Studies from "./pages/Studies";
import StudyPlace from "./pages/StudyPlace";
import * as serviceWorker from "./serviceWorker";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "about",
    element: <About />,
  },
  {
    path: "/:id",
    element: <SalaryPage />,
  },
  {
    path: "confessions",
    element: <CareerConfession />,
  },
  {
    path: 'contact',
    element: <Contact />,
  },
  {
    path: 'roles',
    element: <Roles />,
  },
  {
    path: '/roles/:roleId',
    element: <Role />,
  },
  {
    path: "/design",
    element: <Design />,
  },
  {
    path: 'ai-career',
    element: <AiCareer />,
  },
  {
    path: 'studies',
    element: <Studies />,
  },
  {
    path: 'studies/:studyPlaceId',
    element: <StudyPlace />,
  }
]);

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(<RouterProvider router={router} />);

serviceWorker.register();
