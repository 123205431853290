import { useCallback, useEffect, useState } from "react";
import { FaFacebook, FaInstagram, FaTiktok } from "react-icons/fa";
import { Link } from "react-router-dom";
import supabase from "../supabase";
import CareerConfession from "../components/CareerConfession";
import CategoryFilter from "../components/CategoryFilter";
import Footer from "../components/Footer";
import Loader from "../components/Loader";
import Navbar from "../components/Navbar/Navbar";
import SalarySubmitted from "../components/SalarySubmitted";
import NewCareerForm from "../components/NewCareerForm";
import { Helmet } from "react-helmet";
import ScrollToTopButton from "../components/ScrollToTop";
import Header from "../components/Header";
import SubmitConfessionModal from "../components/SubmitConfessionModal";
import ModalProvider from "../components/ModalProvider";
function CareerConfessions({}) {
  const CATEGORIES = [
    { name: "הייטק", color: "#00323E" },
    { name: "פיננסים וביטוח", color: "#004E58" },
    { name: "כללי", color: "#00706C" },
    { name: "המגזר הציבורי", color: "#1F9894" },
    { name: "עצמאים", color: "#F68D47" },
    { name: "משפטים", color: "#EF6E3F" },
    { name: "מכירות", color: "#DD4C39" },
    { name: "אחר", color: "#C83641" },
    { name: "עיצוב, פרסום ושיווק", color: "#D45E64" },
  ];

  const [careerConfessions, setCareerConfessions] = useState([]);
  const [currentCategory, setCurrentCategory] = useState("הכול");
  const [shouldFetchCareerConfessions, setShouldFetchCareerConfessions] =
    useState(false);
  const [page, setPage] = useState(1);
  // const [pageSize, setPageSize] = useState(10);
  // const startIndex = (page - 1) * pageSize;
  // const endIndex = startIndex + pageSize;
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [careerformSubmitted, setCareerFormSubmitted] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);


  function handleOpenModal() {
    setShowModal(true);
  }

  function handleCloseModal() {
    setShowModal(false);
  }

  //   fetch careerConfessions from the database
  const fetchCareerConfessions = useCallback(async () => {
    setIsLoading(true);
    let query = supabase.from("career-confessions").select("*");

    if (currentCategory !== "הכול") {
      query = query.eq("category", currentCategory).eq("approved", true);
    } else {
      query = query.eq("approved", true);
    }

    const { data, error } = await query;
    if (!error) setCareerConfessions(data);
    else <p className="message">אופס!: {error.message}</p>;
    setIsLoading(false);
  }, [currentCategory]);


  useEffect(() => {
    fetchCareerConfessions();
  }, [fetchCareerConfessions, setShouldFetchCareerConfessions]);


  const handleCareerFormSubmit = (e) => {
    setFormSubmitted(true);
    setTimeout(() => {
      setFormSubmitted(false);
    }, 3000);
  };

  return (
    <>
      <Helmet>
        <title>ריפרש - וידויי קריירה</title>
        <meta
          name="description"
          content="ריפרש היא פלטפורמה לשיתוף וידויי שכר וקריירה אנונימיים משוק העבודה הישראלי. גלו כמה אתם אמורים להרוויח באמת !"
        />
        <meta
          property="og:description"
          content="ריפרש היא פלטפורמה לשיתוף וידויי שכר וקריירה אנונימיים משוק העבודה הישראלי"
        />
      </Helmet>

      <ModalProvider
          formSubmitted={formSubmitted}
          handleOpenModal={handleOpenModal}
          handleCloseModal={handleCloseModal}
          onFormSubmit={handleCareerFormSubmit}
      >

      {/* <Header
      formSubmitted={formSubmitted}
      handleCareerFormSubmit={handleCareerFormSubmit}


      /> */}

  

     {careerformSubmitted ? <SalarySubmitted /> : null}
     {showModal ? (  <SubmitConfessionModal handleCloseModal={handleCloseModal} />) : null}
      
{/* <div className="about-header">
        <header>
          <div className="logo">


            <h1>
              <a href="/" className="homeHeading">
                ריפרש
              </a>
              <div className="header-social">
                <a
                  href="https://www.instagram.com/salary_confessions_il/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaInstagram />
                </a>
                <a
                  href="https://www.tiktok.com/@salary_confessions_il"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaTiktok />
                </a>
                <a
                  href="https://www.facebook.com/profile.php?id=100090176464932"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaFacebook />
                </a>
              </div>
            </h1>
            <h2>וידויי שכר אנונימיים משוק העבודה בישראל !</h2>
            <Navbar />
          </div>
          {careerformSubmitted ? <SalarySubmitted /> : null}
          <button onClick={handleOpenModal} className="btn btn-large open-btn">
            שתף וידוי
          </button>
          {showModal ? (
            <NewCareerForm
              handleCloseModal={handleCloseModal}
              onFormSubmit={handleFormSubmit}
              setCareerConfessions={setCareerConfessions}
            />
          ) : null}
        </header>
      </div> */}

      <main className="main">
        <div>
        <CategoryFilter
          setCurrentCategory={setCurrentCategory}
          setShouldFetchCareerConfessions={setShouldFetchCareerConfessions}
          setPage={setPage}
        />
          </div>

          <div>

          {isLoading ? (
            <Loader />
          ) : careerConfessions.length === 0 ? (
            <p className="message">אין תוצאות</p>
          ) : (
            <>
                      <div className="flex items-center space-x-2 pb-4">
  <p className="mr-2 mt-3 searchText">איזה תפקיד בא לך לגלות היום?</p>
  </div><ul className="facts-list">
              {careerConfessions.map((confession, index) => (
                <CareerConfession confession={confession} key={confession.id} />
              ))}
            </ul></>
          )}
          </div>

        <ScrollToTopButton />
      </main>

      <Footer />
      </ModalProvider>
    </>
  );
}

export default CareerConfessions;
