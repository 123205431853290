function CareerConfession({ confession }) {
  const CATEGORIES = [
    { name: "הייטק", color: "#00323E" },
    { name: "פיננסים וביטוח", color: "#004E58" },
    { name: "כללי", color: "#00706C" },
    { name: "המגזר הציבורי", color: "#1F9894" },
    { name: "עצמאים", color: "#F68D47" },
    { name: "משפטים", color: "#EF6E3F" },
    { name: "מכירות", color: "#DD4C39" },
    { name: "אחר", color: "#C83641" },
    { name: "עיצוב, פרסום ושיווק", color: "#D45E64" },
  ];

  return (
    <>
      <li
        className="fact"
        style={{
          "--tag-color": CATEGORIES.find(
            (cat) => cat.name === confession.category
          ).color,
        }}
      >
        {" "}
        <p className="role" style={{ color: "black" }}>
          {" "}
          {confession.confession}{" "}
        </p>
      </li>

      {/* <span
            className="tag"
            style={{
              backgroundColor: CATEGORIES.find(
                (cat) => cat.name === confession.category
              ).color,
            }}
          >
            {confession.category}
          </span> */}
    </>
  );
}

export default CareerConfession;
