import { Helmet } from 'react-helmet';
import { useState } from 'react';
import Navbar from "../components/Navbar/Navbar";
import supabase from '../supabase';


function Contact() {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!name || !email || !message) {
            alert('אנא מלא את כל השדות')
            return
        }
        const { data, error } = await supabase
        .from('contact')
        .insert([
            { name, email, message },
        ])

        if (error) {
            alert('ארעה שגיאה, אנא נסה שוב')
            return
        }
        
    setName('');
    setEmail('');
    setMessage('');
    }







  return (
    <>
    <Helmet>
      <title>צור קשר</title>
      <meta name="description" content="ריפרש היא פלטפורמה לשיתוף וידויי שכר וקריירה אנונימיים משוק העבודה הישראלי. גלו כמה אתם אמורים להרוויח באמת !" />
      <meta property="og:description" content="ריפרש היא פלטפורמה לשיתוף וידויי שכר וקריירה אנונימיים משוק העבודה הישראלי" />
    </Helmet>
    <div className="about-header">
        <header>
        <div className="logo">
        <h1>
            <a href="/" className="homeHeading">
              ריפרש
            </a>
          </h1>
          <h2>וידויי שכר אנונימיים משוק העבודה בישראל !</h2>
          <Navbar />
        </div>
       
      </header>
      </div>
      <section className="about-section">
      <div className="contact-form">
  <form onSubmit={handleSubmit}>
    <div className="form-group">
      <label htmlFor="contact-name">שם:</label>
      <input type="text" className="form-control form-text" id="contact-name" 
      value={name}
      onChange={
            (e) => {
                setName(e.target.value)
            }
      } />
    </div>
    <div className="form-group">
      <label htmlFor="contact-email">אימייל:</label>
      <input type="email" className="form-control form-text" id="contact-email"
      value={email}
      onChange={
        (e) => {
            setEmail(e.target.value)
        }
  }
      />
    </div>
    <div className="form-group">
      <label htmlFor="contact-message">הודעה:</label>
      <textarea className="form-control form-text" id="contact-message" rows="5"
      value={message}
        onChange={
            (e) => {
                setMessage(e.target.value)
            }
        }
      ></textarea>
    </div>
    <button type="submit" className="btn btn-primary">שלח</button>
  </form>
</div>

            {/* <div className="form-group">
              <label htmlFor="name">שם:</label>
              <input type="text" className="form-control" id="name" />
            </div>
            <div className="form-group">
              <label htmlFor="email">אימייל:</label>
              <input type="email" className="form-control" id="email" />
            </div>
            <div className="form-group">
              <label htmlFor="message">הודעה:</label>
              <textarea className="form-control" id="message" rows="5"></textarea>
            </div>
            <button type="submit" className="btn btn-primary">שלח</button>
          </form>
          <div className="socialbtn">
            <a href="https://www.instagram.com/salary_confessions_il/" target="_blank" rel="noreferrer">
              <FaInstagram />
            </a>
            <a href="https://www.tiktok.com/@salary_confessions_il" target="_blank" rel="noreferrer">
              <FaTiktok />
            </a>
            <a href="https://www.facebook.com/profile.php?id=100090176464932" target="_blank" rel="noreferrer">
              <FaFacebook />
            </a>
          </div> */}
      </section>
    </>
  )
}

export default Contact