
const CATEGORIES = [
  { name: "הייטק", color: "#00323E" },
  { name: "פיננסים וביטוח", color: "#004E58" },
  { name: "כללי", color: "#00706C" },
  { name: "המגזר הציבורי", color: "#1F9894" },
  { name: "עצמאים", color: "#F68D47" },
  { name: "משפטים", color: "#EF6E3F" },
  { name: "מכירות", color: "#DD4C39" },
  { name: "אחר", color: "#C83641" },
  { name: "עיצוב, פרסום ושיווק", color: "#D45E64" },
];

function CategoryFilter({
  setCurrentCategory,
  setPage,
  setShouldResetSearch,
}) {
  return (
    <>
    <aside className="category-filter">
      <ul>
        <li className="category">
          <button
            className="btn btn-all"
            onClick={() => {
              setCurrentCategory("הכול");
              setPage(1);
              setShouldResetSearch(false);
            }}
          >
            הכול
 
          </button>
        </li>

        {CATEGORIES.map((category) => (
          <li key={category.name} className="category">
            <button
              onClick={() => {
                setCurrentCategory(category.name);
                setPage(1);
              }}
              className="btn btn-category"
              style={{ backgroundColor: category.color }}
            >
              {category.name}
            </button>
          </li>
        ))}
      </ul>
    </aside>
    </>
  );
}

export default CategoryFilter;
