import Navbar from "../components/Navbar/Navbar";
import { FaFacebook, FaInstagram, FaTiktok } from "react-icons/fa";
import { Helmet } from 'react-helmet';
import Header from "../components/Header";
import { useState } from "react";
import supabase from "../supabase";
import Footer from "../components/Footer";
import ModalProvider from "../components/ModalProvider";


function About({showModal, handleOpenModal}) {

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
      e.preventDefault();
      if (!name || !email || !message) {
          alert('אנא מלא את כל השדות')
          return
      }
      const { data, error } = await supabase
      .from('contact')
      .insert([
          { name, email, message },
      ])

      if (error) {
          alert('ארעה שגיאה, אנא נסה שוב')
          return
      }
      
  setName('');
  setEmail('');
  setMessage('');
  }
    return (
        <>

          <Helmet>
            <title>עלינו</title>
            <meta name="description" content="ריפרש היא פלטפורמה לשיתוף וידויי שכר וקריירה אנונימיים משוק העבודה הישראלי. גלו כמה אתם אמורים להרוויח באמת !" />
            <meta property="og:description" content="ריפרש היא פלטפורמה לשיתוף וידויי שכר וקריירה אנונימיים משוק העבודה הישראלי" />

          </Helmet>
       
       <ModalProvider>

       <main className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8 mt-24 ">
  <div className="flex flex-col sm:flex-row lg:gap-8">
    <div className="bg-gray-300 rounded-lg shadow-lg overflow-hidden flex-1 mb-8 sm:mb-0">
      <div className="p-4">
        <h3 className="text-xl text-teal-600 mb-2 font-bold">
          עלינו
        </h3>
        <p className="text-black-500 font-bold mb-4 text-md">
        ריפרש היא פלטפורמה לשיתוף וידויי שכר אנונימיים בה תוכלו לשתף ולגלות וידויי שכר משוק העבודה הישראלי.
                רירפש הוקמה במטרה לעזור לעובדים ולמעסיקים במשק לגלות את השכר האמיתי בשוק, בלי ממוצעים וחציונים, אלא שכר אמיתי מעובדים לעובדים. וידויי השכר במערכת מחולקים לפי התחומים החמים בשוק העבודה וכוללים מידע מורחב כמו גיל,השכלה,ניסיון, הטבות, תנאים ועוד בשביל לעזור לכם לקבל החלטות קריירה ושכר חכמות.
                בריפרש אנחנו ששקיפות ופתיחות סביב נושא השכר יכול לעזור לכולנו לקבל החלטות מושכלות לגבי הקריירה והעתיד הפיננסי שלנו.
                הפלטפורמה שלנו מספקת מרחב בטוח ומאובטח לחלוק את המשכורת שלכם. בין אם אתם רוצים לפרוק , להשוות את השכר לאחרים בתחום, או פשוט לספק את הסקרנות שלכם, אנחנו כאן כדי לעזור.
                <br></br><br></br>
               רוצים לעזור גם? שתפו את השכר שלכם על מנת להגדיל את הקהילה ולעזור לכולנו.
        </p>
      </div>
    </div>
    <div className="bg-gray-300 py-4 px-1 shadow sm:rounded-lg sm:px-10 flex-1 ">
      <h3 className="text-xl text-teal-600 mb-2 font-bold">
        צור קשר
      </h3>
      <form onSubmit={handleSubmit}>
    
    <div className="form-group">
      <label htmlFor="contact-name">שם:</label>
      <input type="text" className="form-control form-text" id="contact-name" 
      value={name}
      onChange={
            (e) => {
                setName(e.target.value)
            }
      } />
    </div>
    <div className="form-group">
      <label htmlFor="contact-email">אימייל:</label>
      <input type="email" className="form-control form-text" id="contact-email"
      value={email}
      onChange={
        (e) => {
            setEmail(e.target.value)
        }
  }
      />
    </div>
    <div className="form-group">
      <label htmlFor="contact-message">הודעה:</label>
      <textarea className="form-control form-text" id="contact-message" rows="5"
      value={message}
        onChange={
            (e) => {
                setMessage(e.target.value)
            }
        }
      ></textarea>
    </div>
    <button type="submit" className="bg-yellow-400 rounded-xl px-5 text-lg font-bold">שלח</button>

  </form>
    </div>
  </div>
</main>



  
</ModalProvider>
      </>
    );
  }

export default About