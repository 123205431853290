import supabase from "../supabase";
import { FaSearch } from "react-icons/fa";
function SearchBar({
    searchQuery,
    setSearchQuery,
    setSalaries,
    setIsLoading,
    setShouldResetSearch,
  }) {
    async function searchSalaries() {
      const { data: salaries, error } = await supabase
        .from("salaries")
        .select("*")
        .ilike("role", `%${searchQuery}%`)
        .eq("approved", true)
        .limit(100);
  
      if (!error && searchQuery.length > 0) {
      setSalaries(salaries);
      setShouldResetSearch(true);
      }
      else <p className="message">אופס!: תקלה {error.message}</p>;
      setIsLoading(false);
      setSearchQuery("");
    }

  
    return (
      <>
      
<div className="flex items-center  pb-4 searchDiv">
  <p className="mr-2 searchText">איזה תפקיד בא לך לגלות היום?</p>
  <div className="flex items-center bg-gray-200 rounded-full py-2 px-4 flex-row-reverse">

    <button onClick={searchSalaries}><FaSearch className="text-teal-600" size={20} /></button>
    <input
      className="text-lg bg-gray-200 text-gray-800 rounded-full outline-none"
      type="text"
      placeholder="חפש/י תפקיד"
      value={searchQuery}
      onChange={(e) => setSearchQuery(e.target.value)}
      onKeyPress={(e) => {
        if (e.key === "Enter") {
          searchSalaries();
        }
      }}
    />
  </div>
</div>











 

      </>
    );
  }

  export default SearchBar;