import { useCallback, useEffect, useState } from "react";
import { FacebookShareButton } from "react-share";
import {BiLike, BiDislike, BiShareAlt} from 'react-icons/bi';
import { FaFacebook } from "react-icons/fa";
import { Link } from "react-router-dom";


function Salary({ salary }) {
  const CATEGORIES = [
    { name: "הייטק", color: "#00323E" },
    { name: "פיננסים וביטוח", color: "#004E58" },
    { name: "כללי", color: "#00706C" },
    { name: "המגזר הציבורי", color: "#1F9894" },
    { name: "עצמאים", color: "#F68D47" },
    { name: "משפטים", color: "#EF6E3F" },
    { name: "מכירות", color: "#DD4C39" },
    { name: "אחר", color: "#C83641" },
    { name: "עיצוב, פרסום ושיווק", color: "#D45E64" },
  ];


  return (
    <>
      <li className="fact"   style={{
    '--tag-color': CATEGORIES.find(
      (cat) => cat.name === salary.category
    ).color,
  }}
      >

        <Link to={`/${salary.id}`} className="salary-page-link">
          <p className="role">{salary.role} | {salary.category}</p>
          <p>שכר: {salary.salary.toLocaleString()}</p>
          <p>חברה: {salary.company || "לא צוין"}</p>
          <p>ניסיון: {salary.expiernce}</p>
          <p>השכלה: {salary.education}</p>
          <p>גיל: {salary.age}</p>
          <p>תנאים והטבות: {salary.more || "לא צוין"}</p>



          {/* <span
            className="tag"
            style={{
              backgroundColor: CATEGORIES.find(
                (cat) => cat.name === salary.category
              ).color,
            }}
          >
            {salary.category}
          </span> */}
        </Link>
       <div className="vote">
          <FacebookShareButton
            className="fbshare"
            url={`http://refresh-salaries.com/${salary.id}`}
            quote={`רוצים לדעת כמה מרוויח ${salary.role}?`}
          >
            <button className="bg-teal-600 text-white rounded-full px-5 py-1 text-sm">שתף</button>
          </FacebookShareButton>
      </div>
      </li>
    </>
  );
}

export default Salary;
