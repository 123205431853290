import React, { useState } from "react";
import Header from "./Header";
import SubmitConfessionModal from "./SubmitConfessionModal";
import SalarySubmitted from "./SalarySubmitted";
function ModalProvider({ children, onFormSubmit, handleOpenModal, handleCloseModal }) {
    const [showModal, setShowModal] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    
  
    function handleOpenModal() {
      setShowModal(true);
    }
  
    function handleCloseModal() {
        setShowModal(false);
        setFormSubmitted(false);
      }
    
      const handleFormSubmit = () => {
        setIsSuccess(true);
        setFormSubmitted(true);
        setTimeout(() => {
          setFormSubmitted(false);
          setIsSuccess(false);
        }, 3000);
      };
  
    return (
      <>
         <Header
        handleOpenModal={handleOpenModal}
        formSubmitted={formSubmitted}
        onFormSubmit={handleFormSubmit}
        isSuccess={isSuccess}
      />
      {formSubmitted && <SalarySubmitted />}
      {showModal && (
        <SubmitConfessionModal
          closeModal={handleCloseModal}
          onFormSubmit={handleFormSubmit}
        />
      )}
        {children}
      </>
    );
  }

    export default ModalProvider;