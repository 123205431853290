import { Link } from "react-router-dom";
import Salary from "./Salary";
function SalaryList({
  salaries,
  setSalaries,
  pageSize,
  page,
  setPage,
  
}) {
  const startIndex = (page - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const displayedSalaries = salaries.slice(startIndex, endIndex);
  if (salaries.length === 0) {
    return <p className="message">עדיין אין וידויי שכר עבור הקטגוריה הזו !</p>;
  }

  return (
    <>

      <section> 

        <ul className="facts-list">
          {displayedSalaries.map((salary, index) => (
            <Salary salary={salary} key={salary.id} setSalaries={setSalaries} />
          ))}


        </ul>


        {/* <div className="pages-btns">
          <button
            className="btn-page"
            disabled={page === 1}
            onClick={() => setPage(page - 1)}
          >
            עמוד קודם
          </button>
          <button
            className="btn-page"
            disabled={endIndex >= salaries.length}
            onClick={() => setPage(page + 1)}
          >
            עמוד הבא
          </button>
        </div> */}
      </section>
    </>

  );
}

export default SalaryList;
