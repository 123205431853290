import { Link } from 'react-router-dom'
import '../../style.css'

export default function Navbar() {
  return (
    <div>

        <ul className='navbarul'>
                <li className='navlink'><Link to='/' style={{textDecoration: 'none'}}>בית</Link></li>
                <li className='navlink'><Link to='/about' style={{textDecoration: 'none'}}>עלינו</Link></li>
                <li className='navlink'><Link to='/contact' style={{textDecoration: 'none'}}>צור קשר</Link></li>
        </ul>
    </div>
  )
}
