import { FaFacebook, FaTwitter, FaInstagram } from "react-icons/fa";
import CategoryFilter from "../components/CategoryFilter";
import SalaryList from "../components/SalaryList";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Salary from "../components/Salary";

function Design() {
    const [showFooter, setShowFooter] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleScroll = () => {
    const windowHeight =
      "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight;
    const body = document.body;
    const html = document.documentElement;
    const documentHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );
    const windowBottom = windowHeight + window.pageYOffset;
    if (windowBottom >= documentHeight) {
      setShowFooter(true);
    } else {
      setShowFooter(false);
    }
  };

  return (
    <>
    <div className="flex flex-col min-h-screen">
    <header className="bg-teal-600 py-4 flex justify-between items-center px-64">
  <div className="text-right mr-4">
    <h1 className="text-white text-3xl font-bold">Title</h1>
    <h2 className="text-white text-xl">Subtitle</h2>
  </div> 

  <nav className="text-center flex-grow">
    <ul className="flex justify-center">
      <li className="mr-6">
        <a href="#" className="text-white hover:text-gray-300">וידויי שכר</a>
      </li>
      <li className="mr-6">
        <a href="#" className="text-white hover:text-gray-300">וידויי קריירה</a>
      </li>
      <li className="mr-6">
        <a href="#" className="text-white hover:text-gray-300">תפקידים בהייטק</a>
      </li>
      <li className="mr-6">
        <a href="#" className="text-white hover:text-gray-300">עלינו</a>
      </li>
      <li className="mr-6">
        <a href="#" className="text-white hover:text-gray-300">צור קשר</a>
      </li>
      <li className="mr-6">
        <button className="bg-yellow-400 rounded-md px-4">
          <Link to="/new-salary">הוסף וידוי</Link>
        </button>
      </li>
    </ul>
  </nav>

  <div className="flex items-center ml-4">
    <a href="#">
      <FaFacebook className="text-white text-xl mr-4" />
    </a>
    <a href="#">
      <FaTwitter className="text-white text-xl mr-4" />
    </a>
    <a href="#">
      <FaInstagram className="text-white text-xl" />
    </a>
  </div>
</header>
    <main className="main pageContent">
        <CategoryFilter />


        <section> 

        <ul className="facts-list">
    
        <li className="fact">
          <p className="role">תפקיד: </p>
          <p>שכר: </p>
          <p>חברה: </p>
          <p>ניסיון: </p>
          <p>השכלה: </p>
          <p>גיל: </p>
          <p>תנאים והטבות: </p>

         

      </li>
        <li className="fact">
          <p className="role">תפקיד: </p>
          <p>שכר: </p>
          <p>חברה: </p>
          <p>ניסיון: </p>
          <p>השכלה: </p>
          <p>גיל: </p>
          <p>תנאים והטבות: </p>

         

      </li>
        <li className="fact">
          <p className="role">תפקיד: </p>
          <p>שכר: </p>
          <p>חברה: </p>
          <p>ניסיון: </p>
          <p>השכלה: </p>
          <p>גיל: </p>
          <p>תנאים והטבות: </p>

         

      </li>
         
        </ul>

        {/* <p className="email">salaryconfessionsil@gmail.com</p> */}

       
      </section>
</main>



<div className="divfooter">
      <span className="tnwuijualdzvc">
        ריפרש היא פלטפורמה לשיתוף וידויי שכר אנונימיים משוק העבודה הישראלי.
        בעזרת ריפרש תוכלו לגלות את השכר האמיתי בתפקיד שלכם ושל הקולגות !
      </span>
    </div>

{/* <footer className="bg-teal-600 py-4 flex flex-col justify-center items-center fixed bottom-0 w-full">

     <div className="flex items-center">
   <a href="#">
       <FaFacebook className="text-white text-xl mx-4" />
     </a>
    <a href="#">
         <FaTwitter className="text-white text-xl mx-4" />
     </a>
        <a href="#">
          <FaInstagram className="text-white text-xl mx-4" />
        </a>
      </div>


          <p className="text-white text-sm mt-4">
          Copyright © {new Date().getFullYear()}
        </p>
      </footer> */}

    </div>
    </>
  )
}

export default Design