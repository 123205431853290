import React from 'react'
import CareerConfessionForm from './CareerConfessionForm'
import SalaryConfessionForm from './SalaryConfessionForm'
import { useState } from 'react'
function SubmitConfessionModal({ closeModal, onFormSubmit }) {
  const [postType, setPostType] = useState("");

  return (
    <div className="fixed z-50 inset-0 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen">
        <div className="fixed inset-0 bg-gray-900 bg-opacity-50"></div>
        <div className="bg-white rounded-lg overflow-hidden shadow-xl max-w-md w-full mx-auto z-50 relative submitModal">
          <button
            className="absolute top-2 right-2 text-gray-400 hover:text-gray-500 focus:outline-none"
            onClick={closeModal}
          >
            <svg
              className="h-6 w-6 fill-current"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path
                fillRule="evenodd"
                d="M15.707 16.707a1 1 0 01-1.414 0L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 011.414-1.414L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 010 1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
          <div className="px-6 py-12">
  <div className="text-center">
    <h2 className="text-2xl font-bold mb-2 text-teal-600">שתף וידוי</h2>
    <p className="text-black-500 mb-4">
      בחר את סוג הוידוי שברצונך לשתף
    </p>
    <div className="flex justify-center submitModalBtns">
  <button
    className="flex-1 w-full sm:w-auto bg-yellow-400 rounded-xl px-6 sm:px-12 text-base sm:text-md font-bold"
    onClick={() => setPostType("שתף וידוי קריירה")}
  >
    וידוי קריירה
  </button>
  <div className="w-6"></div>
  <button
    className="flex-1 w-full sm:w-auto bg-yellow-400 rounded-xl px-6 sm:px-12 text-base sm:text-md font-bold"
    onClick={() => setPostType("שתף וידוי שכר")}
  >
    וידוי שכר
  </button>
</div>



  </div>
            {postType === "שתף וידוי קריירה" && (
              <CareerConfessionForm
                handleCloseModal={closeModal}
                onFormSubmit={onFormSubmit}
              />
            )}
            {postType === "שתף וידוי שכר" && (
              <SalaryConfessionForm
                handleCloseModal={closeModal}
                onFormSubmit={onFormSubmit}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubmitConfessionModal;
