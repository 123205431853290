import { useEffect, useState } from 'react'
import {useParams, Link} from 'react-router-dom'
import supabaseRoles from '../supabaseRoles'
import Navbar from '../components/Navbar/Navbar'
import Loader from '../components/Loader'
import './Roles.css'
import Header from '../components/Header'

import ModalProvider from '../components/ModalProvider'

function StudyPlace() {
    const {studyPlaceId} = useParams()
    const [data, setData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    useEffect(() => {
        const getStudyPlace = async () => {
            setIsLoading(true)
            let {data, error} = await supabaseRoles.from('education').select('*').eq('id', studyPlaceId).single()
            if (error) {
                console.log(error)
            }
            else {
                setData(data)
                setIsLoading(false)
            }
        }
        getStudyPlace()
    }, [studyPlaceId])



  return (
    <>
    <ModalProvider>
        <Header />
        <main className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8 mt-24 ">
            <h1 className="text-center text-3xl mb-4 text-teal-600 font-bold">על התואר...</h1>
            <div>
                <div className="content-wrapper-role">
                    {isLoading && <Loader />}
                    {!isLoading && (
                        <>
                            <li className="roleFact" style={{ margin: "0 auto" }}>
                                <p className="role"> {data.degree}</p>
                                <p>תיאור: {data.description}</p>
                                <div className='stack'>
                                <p>מוסדות לימוד: 
                                {data.places?.split(",")?.map((tool) => (
                                    <span key={tool.trim()}>{tool.trim()}</span>
                                )) ?? "בטעינה"}
                                </p>
                                </div>
                            </li>
                            <div className="btnDiv">
                                <Link to="/studies">
                                    <button className="backHome">חזרה לתארים</button>
                                </Link>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </main>

    </ModalProvider>

    </>
  )
}

export default StudyPlace