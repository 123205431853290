const CACHE_NAME = 'pwa-cache';
const urlsToCache = ['/index.html', '/offline.html', '/css/styles.css', '/js/app.js'];

self.addEventListener('install', function(event)  { /* eslint-disable-line no-restricted-globals */
  event.waitUntil(
    caches.open(CACHE_NAME).then(function(cache) {
      return cache.addAll(urlsToCache);
    })
  );
});

self.addEventListener('fetch', function(event) { /* eslint-disable-line no-restricted-globals */
  event.respondWith(
    caches.match(event.request).then(function(response) {
      if (response) {
        return response;
      }
      return fetch(event.request).then(function(response) {
        if (!response || response.status !== 200 || response.type !== 'basic') {
          return response;
        }
        var responseToCache = response.clone();
        caches.open(CACHE_NAME).then(function(cache) {
          cache.put(event.request, responseToCache);
        });
        return response;
      });
    }).catch(function() {
      return caches.match('/offline.html');
    })
  );
});

// const CACHE_NAME = "pwa-cache";
// const urlsToCache = ["/", "/index.html"];

// self.addEventListener("install", (event) => {
//   event.waitUntil(
//     caches.open(CACHE_NAME).then((cache) => {
//       return cache.addAll(urlsToCache);
//     })
//   );
// });

// self.addEventListener("fetch", (event) => {
//   event.respondWith(
//     caches.match(event.request).then((response) => {
//       return response || fetch(event.request);
//     })
//   );
// });